import React from "react";
import styled from "styled-components"
import { Heading, Text} from '../Layout/pageParts';

const Container = styled.div`      
    width: 100%;   
`;

const Date = styled.div`
    margin-top:3px;
    font-size: 12px;   
    font-weight: 400;
    margin-bottom:0px;
`;

const HeadingSlim = styled(Heading)`
    margin-bottom:10px;
`;

const BlogPost = ({title, date, content}) => {
    return (
        <Container>
            <HeadingSlim>{title}<Date>{date}</Date></HeadingSlim>  
            <Text dangerouslySetInnerHTML={{ __html: content}} />
        </Container>
    );
}

export default BlogPost;