import React from "react";
import styled from "styled-components"
import Layout from "../Layout/layout"
import { graphql, Link } from "gatsby"
import BlogPost from "./blogPost";

const Container = styled.div`    
    padding: 0;
    margin-left:20px;
    margin-right:20px;
    margin-top: 20px;    
`;

const NavBar = styled.div`
    margin-top: 20px;           
    display: flex;
    justify-content: space-between;
  `;  

const BlogPostPage = ({data, pageContext}) => {
    
  const post = data.markdownRemark;  
  const { previous, next } = pageContext;

    return (
      <Layout>   
        <Container>
          <BlogPost title={post.frontmatter.title} date={post.frontmatter.date} description={post.frontmatter.description} content={post.html} />         
          <NavBar>        
            {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
                  {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
          </NavBar>
        </Container>
      </Layout>    
    )
}

export default BlogPostPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
